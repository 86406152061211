<template>
  <div>
    <vs-alert
      :active.sync="showDismissibleAlert"
      closable
      icon-pack="feather"
      close-icon="icon-x"
      color="danger"
      class="p-1"
    >{{ message }}
    </vs-alert>
    <br />
    <vs-input
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder="Email"
        v-model="email"
        class="w-full"/>
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>
<br/>
    <vs-input
        data-vv-validate-on="blur"
        v-validate="'required'"
        name="password"
        icon-no-border
        icon-pack="feather"
        label-placeholder="Password"
        v-model="password"
        class="w-full mt-6"
        :icon="passwordIcon"
        :type="passwordType"
        @click.native="showHidePassword($event)"
    />
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <div class="flex flex-wrap justify-between my-5">
        <vs-checkbox v-model="rememberMe" class="mb-3">Remember Me</vs-checkbox>
        <router-link to="/admin/forgot-password">Forgot Password?</router-link>
    </div>
    <div class="flex flex-wrap justify-end mb-3">
      <!--<vs-button  type="border" @click="registerUser">Register</vs-button>-->
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      showDismissibleAlert: false,
      message: "",
      showPassword: false,
      passwordType: 'password',
      passwordIcon: 'icon icon-eye-off'
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != '' && this.password != '';
    },
  },
  methods: {
    showHidePassword(event){
      if(event.target.className === "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border"
        || event.target.className === "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border")
      {
        this.showPassword = !this.showPassword
        this.passwordType === 'password' ? this.passwordType='text' : this.passwordType='password'
        this.passwordIcon === 'icon icon-eye-off' ? this.passwordIcon='icon icon-eye' : this.passwordIcon='icon icon-eye-off'
      }
      return true
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    loginJWT() {
      const payload = {
        rememberMe: this.rememberMe,
        userDetails: {
          email: this.email,
          password: this.password
        }
      }

      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          this.$store
            .dispatch("auth/loginJWT", payload)
            .then(response => {
              this.$vs.loading.close();
            })
            .catch(error => {
              console.log(error.data);
              this.showDismissibleAlert = true;
              this.message = error.message;
              this.$vs.loading.close();
              /*this.$vs.notify({
                  title: 'Error',
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })*/
            });
        }
      }).catch(error => {
        this.showDismissibleAlert = true;
        this.message = error && error.message ? error.message : 'Server Error';
        this.$vs.loading.close();
      });
      // if (!this.checkLogin()) return
      //
      // // Loading
      // this.$vs.loading()
      //
      // const payload = {
      //   checkbox_remember_me: this.checkbox_remember_me,
      //   userDetails: {
      //     email: this.email,
      //     password: this.password
      //   }
      // }
      //
      // this.$store.dispatch('auth/loginJWT', payload)
      //   .then(() => { this.$vs.loading.close() })
      //   .catch(error => {
      //     this.$vs.loading.close()
      //     this.$vs.notify({
      //       title: 'Error',
      //       text: error.message,
      //       iconPack: 'feather',
      //       icon: 'icon-alert-circle',
      //       color: 'danger'
      //     })
      //   })
    },
    /*registerUser() {
      if (!this.checkLogin()) return
      this.$router.push('/pages/register').catch(() => {})
    }*/
  }
}

</script>

